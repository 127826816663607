<template>
  <Modal
    v-model="visible"
    fullscreen
    :closable="false"
    class-name="aisleSbwModal"
  >
    <p slot="header">
      四八五【{{sbwObj.name}}】的通道配置
      <Icon 
        title="返回设备列表" 
        type="ios-undo" 
        style="color: #08DDDD; position: absolute;top: 12px;right: 20px;font-size: 25px;cursor: pointer"
        @click="$emit('asModalClose')"
      />
    </p>
    <div class = "filterBar flexboxb" style="margin-top: 0">
      <div class = "flexboxs">
        <div class = "filterItem">
          <Select class = "selectMid" :value="filter.status" placeholder="选择通道状态" label-in-value @on-change="({value})=>{filerChange(value, 'status', 'select')}" clearable @on-clear="statusClear">
            <Option :value="0">未绑定</Option>
            <Option :value="1">已绑定</Option>
          </Select>
        </div>
      </div>
      <div v-if="accessData.boardAisleAdd">
        <span class = "btnMy" @click="aisleNew">添加通道</span>
      </div>
    </div>
    <div class="sbwAisleGrid">
      <Table :loading="listLoading" border :columns="listCol" :data="listData" :height="getGridHeight()"></Table>
    </div>
    <div class="pageBar">
        <Page :total="page.total" :current="page.pageNo" :page-size ="page.pageSize" show-elevator show-total @on-change="pageChange"></Page>
    </div>
    <div slot="footer" style="padding: 0">
      <!-- <Button type="primary" @click="$emit('ajModalClose')">返回板卡列表</Button> -->
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'AisleSbwModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    sbwObj: {
      type: Object,
      default: function () {
        return {
          name: '',
          guid: '',
        }
      }
    }
  },
  data () {
    return {
      page: {
        total: 0,
        pageNo: 1,
        totalPage: 0,
        pageSize: 10,
      },
      listCol: [
        {
          title: '通道名称',
          key: 'nodeName',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            return row.isE && row.guid ? 
              c('Input', {
                attrs: {
                    // value: this.newData.name
                },
                'class': {
                    noPass: row.nodeName ? false : true,
                },
                props: {
                    value: row.nodeName,
                },
                ref: 'sbwaislenameInput',
                on: {
                    'on-change': (e) => {
                      // row.name = e.target.value;
                      if (!row.guid) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          nodeName: e.target.value
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            nodeName: e.target.value
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.sbwaislenameInput.focus();
                      })
                    }
                }
              }) : row.isE && !row.guid ? 
              c('span', '--') :
              c('span', row.nodeName)
          }
        },
        {
          title: '通道地址号',
          key: 'addressNo',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            let minNum = c('div', {
              'class': {
                noPass: (row.beginAddressNo || +row.beginAddressNo === 0)
                  && (+row.beginAddressNo <= +row.endAddressNo) ? false : true,
              },
            }, [c('InputNumber', {
              attrs: {
                // value: this.newData.name
              },
              props: {
                value: row.beginAddressNo,
                min: 0,
                step: 1,
              },
              ref: 'sbwaddressaislebeginNoInput',
              on: {
                'on-change': (val) => {
                  // row.name = e.target.value;
                  if (!row.guid) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      beginAddressNo: val
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        beginAddressNo: val
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.sbwaddressaislebeginNoInput.$el.children[1].children[0].focus();
                  })
                }
              }
            })]);
            let maxNum = c('div', {
              'class': {
                noPass: (row.endAddressNo || +row.endAddressNo === 0)
                  && (+row.beginAddressNo <= +row.endAddressNo) ? false : true,
              },
            }, [c('InputNumber', {
              attrs: {
                // value: this.newData.name
              },
              props: {
                value: row.endAddressNo,
                min: 0,
                step: 1,
              },
              ref: 'sbwaddressaisleendNoInput',
              on: {
                'on-change': (val) => {
                  // row.name = e.target.value;
                  if (!row.guid) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      endAddressNo: val
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        endAddressNo: val
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.sbwaddressaisleendNoInput.$el.children[1].children[0].focus();
                  })
                }
              }
            })])
            return row.isE && !row.guid ? 
              c('div', {
                style: {
                  display: 'flex',
                  alignItems: 'center'
                }
              }, [minNum, c('span', {style: {margin: '0 5px'}}, '~'), maxNum]) : c('span', row.addressNo)
          }
        },
        {
          title: '通道节点号',
          key: 'nodeAddress',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            let minNum = c('div', {
              'class': {
                noPass: (row.beginNodeNo || +row.beginNodeNo === 0)
                  && (+row.beginNodeNo <= +row.endNodeNo) ? false : true,
              },
            }, [c('InputNumber', {
              attrs: {
                // value: this.newData.name
              },
              props: {
                value: row.beginNodeNo,
                min: 0,
                step: 1,
              },
              ref: 'sbwnodeaislebeginNoInput',
              on: {
                'on-change': (val) => {
                  // row.name = e.target.value;
                  if (!row.guid) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      beginNodeNo: val
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        beginNodeNo: val
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.sbwnodeaislebeginNoInput.$el.children[1].children[0].focus();
                  })
                }
              }
            })]);
            let maxNum = c('div', {
              'class': {
                noPass: (row.endNodeNo || +row.endNodeNo === 0)
                  && (+row.beginNodeNo <= +row.endNodeNo) ? false : true,
              },
            }, [c('InputNumber', {
              attrs: {
                // value: this.newData.name
              },
              props: {
                value: row.endNodeNo,
                min: 0,
                step: 1,
              },
              ref: 'sbwnodeaisleendNoInput',
              on: {
                'on-change': (val) => {
                  // row.name = e.target.value;
                  if (!row.guid) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      endNodeNo: val
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        endNodeNo: val
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.sbwnodeaisleendNoInput.$el.children[1].children[0].focus();
                  })
                }
              }
            })])
            return row.isE && !row.guid ? 
              c('div', {
                style: {
                  display: 'flex',
                  alignItems: 'center'
                }
              }, [minNum, c('span', {style: {margin: '0 5px'}}, '~'), maxNum]) : c('span', row.nodeAddress)
          }
        },
        {
          title: '状态',
          key: 'statusName',
          minWidth: 100,
          render: (c, {row}) => {
            return row.statusName ? c('span', row.statusName) : c('span', '--')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 100,
          render: (c, {row}) => {
            return !row.isE ? 
              c('span', row.remark) :
                c('Input', {
                  attrs: {
                    // value: this.newData.name
                  },
                  props: {
                    value: row.remark,
                  },
                  on: {
                    'on-change': (e) => {
                      row.remark = e.target.value
                    }
                  }
                })
          }
        },
        {
          title: '操作',
          key: 'action',
          width: 110,
          render: (c, {row}) => {
            if (row.isE) {
              return c('div', [
                c('Icon', {
                  attrs: {
                    title: '保存'
                  },
                  style: {
                    marginRight: '15px',
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-checkmark'
                  },
                  nativeOn: {
                    click: () => {
                      !row.guid ? this.saveAisleNew(row) : this.saveAisleEdit(row);
                    }
                  }
                }),
                c('Icon', {
                  attrs: {
                    title: '取消'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-close'
                  },
                  nativeOn: {
                    click: () => {
                      !row.guid ? this.cancelAisleNew(row) : this.cancelAisleEdit(row)
                    }
                  }
                }),
              ])
            } else {
              let delBtn = c('Icon', {
                attrs: {
                  title: '删除'
                },
                style: {
                  cursor: 'pointer',
                  color: '#08DDDD',
                  fontSize: '16px',
                },
                props: {
                  type: 'md-trash'
                }
              });
              return this.deleteLoading && this.deletingId === row.guid ? c('Spin', {
                props: {
                  size: 'small'
                }
              }) : c('div', [
                this.accessData.boardAisleMod && c('Icon', {
                  attrs: {
                    title: '编辑'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'ios-brush'
                  },
                  nativeOn: {
                    click: () => {
                      this.sbwAisleEdit(row)
                    }
                  }
                }),
                /* c('Icon', {
                  attrs: {
                    title: '通道绑定'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'md-git-compare'
                  },
                  nativeOn: {
                    click: () => {
                      this.aisleSet(row)
                    }
                  }
                }), */
                this.accessData.boardAisleDel && c('Poptip', {
                  props: {
                    confirm: true,
                    title: '确认删除？',
                    placement: 'left'
                  },
                  on: {
                    'on-ok': () => {
                      this.sbwAisleDel(row.guid)
                    },
                    'on-cancel': () => {

                    }
                  }
                }, [delBtn])
              ])
            }
          }
        }
      ],
      listData: [],
      filter: {},
      listLoading: false,
      statusLoading: false,
      deleteLoading: false,
      editLoading: true,
      deletingId: '',
      statusId: '',
      editId: '',
    }
  },

  computed: {
    accessData () {
      return {
        boardAisleAdd: true,// this.$store.state.app.permissionsGroup.boardAisleAdd,
        boardAisleDel: true,// this.$store.state.app.permissionsGroup.boardAisleDel,
        boardAisleMod: true,// this.$store.state.app.permissionsGroup.boardAisleMod,
      }
    }
  },

  methods: {
    getGridHeight () {
      return window.innerHeight - 188
    },

    pageChange (pageNo) {
      this.page.pageNo = pageNo
      this.getList(true)
    },

    getList (loadingShow) {
      loadingShow && (this.listLoading = true);
      this.$post(this.$api.AISLE.SBW_AISLELIST, {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        ...this.filter,
      }).then(resp => {
        this.listData = resp.list.map(item => {
          return {
            ...item,
            farmId: +item.farmId,
          }
        });
        this.page = {
          total: +resp.total,
          pageNo: +resp.pageNo,
          totalPage: +resp.totalPage,
          pageSize: +resp.pageSize,
        }
        loadingShow && (this.listLoading = false);
      }).catch(e => {
        loadingShow && (this.listLoading = false);
      })
    },

    filerChange (value, field, type) {
      if (type === 'select') {
        this.filter = {
          ...this.filter,
          [field]: value,
        };
        this.getList(true)
      }
    },

    statusClear () {
      (this.filter.status || this.filter.status === 0) && delete this.filter.status;
      this.getList(true)
    },

    aisleNew () {
      if (this.listData[0] && this.listData[0].isE) return;
      this.listData.unshift({
        isE: true,
        beginAddressNo: 0,
        endAddressNo: 0,
        beginNodeNo: 0,
        endNodeNo: 0,
      })
    },

    saveAisleNew (record) {
      let noPass = !(record.beginAddressNo || +record.beginAddressNo === 0)
        || !(record.endAddressNo || +record.endAddressNo === 0)
          || !(+record.beginAddressNo <= +record.endAddressNo)
            || !(record.beginNodeNo || +record.beginNodeNo === 0)
              || !(record.endNodeNo || +record.endNodeNo === 0)
                || !(+record.beginNodeNo <= +record.endNodeNo);
      if (noPass) return;
      this.listLoading = true;
      delete record.isE;
      this.$post(this.$api.AISLE.SBW_AISLENEW, {
        ...record,
        deviceId: this.sbwObj.guid,
        createUserId: localStorage.userId,
      }).then(resp => {
        this.getList(true)
      }).catch(e => {
        this.listLoading = false;
      })
    },

    cancelAisleNew () {
      this.listData.shift()
    },

    sbwAisleEdit (record) {
      let listData = this.listData.map(item => {
        return item.guid === record.guid ? {...item, isE: true} : item
      })
      this.listData = listData;
      this.editData = {...record}
    },

    saveAisleEdit (record) {
      let noPass = !record.nodeName;
      if (noPass) return;
      this.editLoading = true;
      this.editId = record.guid;
      this.$post(this.$api.AISLE.SBW_AISLEEDIT, {
        nodeName: record.nodeName,
        guid: record.guid,
        remark: record.remark,
      }).then(resp => {
        this.editLoading = false;
        this.getList(true);
      }).catch(e => {
        this.editLoading = false;
      })
    },

    cancelAisleEdit (record) {
      this.listData = this.listData.map(item => {
        return item.guid === record.guid ? this.editData : item
      });
    },

    sbwAisleDel (guid) {
      this.deletingId = guid;
      this.deleteLoading = true;
      this.$post(this.$api.AISLE.SBW_AISLEDEL, {
        guid
      }).then(resp => {
        this.deleteLoading = false;
        this.getList(true)
      }).catch(e => {
        this.deleteLoading = false;
      })
    },

  },

  watch: {
    sbwObj: {
      handler (newVal, oldVal) {
        this.filter={
          ...this.filter,
          deviceId: newVal.guid,
          aioGuid: newVal.aioGuid,
        }
        this.getList(true)
      },
      deep: true,
    }
  }
}
</script>
<style lang="less">
.aisleSbwModal {
  .ivu-modal-footer {
    padding: 0;
  }
  .ivu-modal-body {
    @footer: 51px;
    height: calc(~"100vh - @{footer}");
  }
  .noPass {
    input {
      border: 1px solid #e4393c;
    }
    .ivu-select-selection {
      border: 1px solid #e4393c;
    }
    .ivu-input-number {
      border: 1px solid #e4393c;
      input {
        border: none;
      }
    }
  }
}
.sbwAisleGrid {
  // margin-top: 15px;
}
</style>


